<template>
  <div>
    <v-row v-if="getInfoErrorMessage">
      <v-col cols="12">
        <no-data with-button :first-text="getInfoErrorMessage"></no-data>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col class="smallFont darkGrey--text" cols="12">
        <span>{{ getAppInfo.name }} </span>/
        <span class="info--text">Releases</span>
      </v-col>
      <v-col>
        <v-card-title>
          <div class="titleFont darkGrey--text">Releases</div>
          <v-spacer></v-spacer>
          <new-release-panel
            v-if="canManageRelease"
            :app-info="getAppInfo"
            @releaseSuccess="$emit('showInstruction')"
          />
        </v-card-title>
      </v-col>
      <v-col cols="12">
        <app-release-stats from-releases-page></app-release-stats>
      </v-col>
      <v-col cols="12">
        <v-card flat>
          <v-tabs v-model="tab">
            <template v-for="(value, key) in tabs">
              <tab
                :key="key"
                v-show="value.permissions"
                :label="value.label"
                :locked="value.locked"
                @click="$router.push({ name: value.value })"
              />
            </template>
          </v-tabs>
          <v-divider></v-divider>
          <router-view></router-view>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  formatBytes,
  get,
  isEmpty,
  numberWithCommas,
} from "@/core/services/helper.service";
import { mapGetters } from "vuex";
import NewReleasePanel from "@/view/components/Releases/NewReleasePanel.vue";
import Tab from "@/view/components/Common/Tab.vue";
import AppReleaseStats from "@/view/components/App/AppReleaseStats";
export default {
  components: {
    AppReleaseStats,
    Tab,
    NewReleasePanel,
  },

  TABS: {
    "app-releases-index": 0,
    "app-comments": 1,
    "app-install": 2,
  },
  data() {
    return {
      formatBytes,
      get,
      isEmpty,
      numberWithCommas,
      tab: 0,
      selectedTab: 1,
      selectedComponent: "app-releases",
      releaseTabs: [
        {
          title: "Releases",
          component: "app-releases-index",
          value: 1,
        },
        {
          title: "Comments",
          component: "app-comments",
          value: 2,
        },
        {
          title: "Installs",
          component: "app-install",
          value: 3,
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getAppInfo: "getAppInfo",
      getTeamPlanUsage: "getTeamPlanUsage",
      getInfoErrorMessage: "getInfoErrorMessage",
    }),
    tabs() {
      return {
        releases: {
          label: "Releases",
          value: "app-release-index",
          permissions: true,
        },
        comments: {
          label: "Comments",
          value: "app-comments",
          permissions: true,
        },
        install: {
          label: "Installs",
          permissions: this.canManageRelease,
          value: "app-install",
        },
      };
    },
  },
  watch: {
    "$route.name"(val) {
      this.tab = this.$options?.TABS[val];
    },
    selectedComponent(val) {
      this.$router.push({
        name: val,
        params: { app_id: this.$route.params.app_id },
      });
    },
  },
  mounted() {
    this.selectedComponent = this.$route.name;
    this.tab = this.$options?.TABS[this.$route.name];
  },
  methods: {
    navigate(item) {
      this.selectedTab = item.value;
      this.selectedComponent = item.component;
    },
    calculateTotalUsage(usage) {
      const { releases = 0, attachments: { comments = 0, feedback = 0 } = {} } =
        usage || {};
      return releases + comments + feedback;
    },
  },
};
</script>

<style scoped></style>
