var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.getInfoErrorMessage ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('no-data', {
    attrs: {
      "with-button": "",
      "first-text": _vm.getInfoErrorMessage
    }
  })], 1)], 1) : _c('v-row', [_c('v-col', {
    staticClass: "smallFont darkGrey--text",
    attrs: {
      "cols": "12"
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.getAppInfo.name) + " ")]), _vm._v("/ "), _c('span', {
    staticClass: "info--text"
  }, [_vm._v("Releases")])]), _c('v-col', [_c('v-card-title', [_c('div', {
    staticClass: "titleFont darkGrey--text"
  }, [_vm._v("Releases")]), _c('v-spacer'), _vm.canManageRelease ? _c('new-release-panel', {
    attrs: {
      "app-info": _vm.getAppInfo
    },
    on: {
      "releaseSuccess": function releaseSuccess($event) {
        return _vm.$emit('showInstruction');
      }
    }
  }) : _vm._e()], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('app-release-stats', {
    attrs: {
      "from-releases-page": ""
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-tabs', {
    model: {
      value: _vm.tab,
      callback: function callback($$v) {
        _vm.tab = $$v;
      },
      expression: "tab"
    }
  }, [_vm._l(_vm.tabs, function (value, key) {
    return [_c('tab', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: value.permissions,
        expression: "value.permissions"
      }],
      key: key,
      attrs: {
        "label": value.label,
        "locked": value.locked
      },
      on: {
        "click": function click($event) {
          return _vm.$router.push({
            name: value.value
          });
        }
      }
    })];
  })], 2), _c('v-divider'), _c('router-view')], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }